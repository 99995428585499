<template>
  <base-modal :modal.sync="modal" @changeModal="e => $emit('change-modal', e)">
    <div class="modal-info-entity">
      <div class="modal__topbar">
        <button class="button modal__close">
          <i class="icon icon-close"></i>
        </button>
      </div>

      <div class="modal__content">
        <div class="modal__header">
          <h2 class="modal__title">{{ `${form.id ? 'Editar' : 'Novo'} usuário` }}</h2>
        </div>
        <div class="modal__form--group d-flex flex-wrap">
          <p class="subtitle_form px-md">Informações Pessoais:</p>
          <r-input v-model="form.name" label="Nome/Razão Social" :rules="rules.name" class="col-12" />
          <r-input v-model="form.cpf" label="CPF/CNPJ" :rules="rules.cpf" class="col-12" :mask="['###.###.###-##', '##.###.###/####-##']" />
          <r-input v-model="form.phone" label="Telefone" :rules="rules.phone" class="col-12" :mask="['(##) ####-####', '(##) #####-####']" />

          <p class="subtitle_form px-md">Informações de acesso:</p>
          <r-select label="Tipo de coletores operacionais" v-model="form.collector_type"
            :items="[
              { name: 'Agente de coleta', id: 4 },
              { name: 'Ponto de coleta', id: 5 },
              { name: 'Empresa coletora', id: 7 }
            ]" class="col-12" labelName="name" placeholder="Selecionar tipo de coletor" />
          <r-input v-model="form.email" label="Email" :rules="rules.email" class="col-12" type="email" />
          <r-input v-if="!form.id" v-model="form.password" label="Senha" :rules="rules.password" class="col-12" type="password" />

          <p class="subtitle_form px-md">Informações de endereço:</p>
          <r-input v-model="form.zip_code" label="CEP" />
          <template v-if="form.city_id">
            <r-input v-model="form.street" label="Endereço" :rules="rules.street" class="col-12" :readonly="loadedByZip.street" />

            <r-input v-model.number="form.number" label="Número" :rules="rules.number" class="col-12 col-md-6" :readonly="loadedByZip.number"/>
            <r-input v-model="form.district" label="Bairro" class="col-12 col-md-6" :rules="rules.district" :readonly="loadedByZip.district" />
            <r-input v-model="form.complement" class="col-12" label="Complemento" :rules="rules.complement" />

            <r-select label="Estado" v-model="form.province_id" :readonly="true" :items.sync="provinces" placeholder="Selecionar estado" labelName="name" class="col-12" />

            <r-select label="Cidade" v-model="form.city_id" :readonly="true" :items.sync="cities" class="col-12" labelName="name" placeholder="Selecionar cidade" />
          </template>

          <div class="footer_modal">
            <button :disabled="loading" @click.prevent="register()" type="button" class="button_outline">{{ loading ? 'Enviando...' : form.id ? 'Atualizar' : 'Cadastrar' }}</button>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert'

const defaultForm = {
  id: '',
  zip_code: '',
  district: '',
  complement: '',
  street: '',
  city_id: '',
  province_id: '',
  name: '',
  phone: '',
  cpf: '',
  cnpj: '',
  collector_type: '',
  email: '',
  password: '',
  number: ''
}
const defaultLoadedByZip = {
  street: false,
  number: false,
  district: false,
  complement: false
}

export default {
  data: () => ({
    form: { ...defaultForm },
    rules: {},
    loading: false,
    isEditing: false,
    loadedByZip: { ...defaultLoadedByZip },
    isOpen: {
      pessoal: false,
      access: false,
      address: false,
      material: false
    },
    avatarBuffer: '',
    provinces: [],
    cities: []
  }),

  props: {
    modal: Boolean,
    editing: {}
  },

  computed: {
    valid () {
      const form = this.form

      if (!form.zip_code) return false
      if (!form.district) return false
      if (!form.street) return false
      if (!form.city_id) return false
      if (!form.province_id) return false
      if (!form.name) return false
      if (!form.phone) return false
      if (!form.cpf) return false
      if (!form.collector_type) return false
      if (!form.email) return false
      if (!form.password && !form.id) return false
      if (!form.number) return false

      return true
    }
  },

  methods: {
    initForm () {
      this.form = { ...defaultForm }
      this.form.materials = this.materials.map(material => {
        return {
          isChecked: false,
          city_company_item_id: material.id,
          name: material.item,
          city: material.city
        }
      })
      this.loading = false
      this.loadedByZip = { ...defaultLoadedByZip }
      this.isOpen = {
        pessoal: false,
        access: false,
        address: false,
        material: false
      }
      this.avatarBuffer = ''
    },
    async register () {
      if (!this.valid) {
        return swal({
          title: 'Atenção!',
          text: 'Preencha todos os campos para salvar o registro.',
          icon: 'error',
          button: 'Ok'
        })
      }

      this.loading = true
      console.log('teste', this.form.cpf.length)
      try {
        if (this.form.cpf.length > 11) {
          this.form.cnpj = this.form.cpf
          this.$delete(this.form, 'cpf')
        } else {
          this.$delete(this.form, 'cnpj')
        }
        if (this.form.id) {
          this.$delete(this.form, 'password')
          await axios.put('/collectors/users/', this.form)
        } else {
          await axios.post('/collectors/users/', this.form)
        }

        swal({
          title: 'Sucesso!',
          text: `Usuário ${this.form.id ? 'atualizado' : 'cadastrado'} com sucesso.`,
          icon: 'success',
          timer: 4000
        }).then(async () => {
          this.$emit('change-modal', false)
          this.initForm()
        })
      } catch (error) {
        await swal({
          title: 'Ooops!',
          text: error.response.data.message,
          icon: 'error',
          button: 'Ok'
        })
      } finally {
        this.loading = false
        this.$parent.getData()
      }
    },
    parseData () {
      const data = new FormData()

      Object.keys(this.form).map(key => {
        const item = this.form[key]
        if (Array.isArray(item)) {
          let count = 0
          item.map(subitem => {
            Object.keys(subitem).map(ik => {
              data.append(`${key}[${count}][${ik}]`, subitem[ik])
            })
            count++
          })
        } else {
          data.append(key, item)
        }
      })
      return data
    },
    async getProvinces () {
      const response = await axios.get('/provinces')
      this.provinces = response.data
    },
    async getCities () {
      const response = await axios.get('cities?province_id=' + this.form.province_id)
      this.cities = response.data
    }
  },

  components: {
    baseModal: () => import('@/components/modal'),
    RSelect: () => import('@/components/select.vue'),
    RInput: () => import('@/components/input.vue')
  },

  async created () {
    await this.getProvinces()
  },

  watch: {
    async 'form.zip_code' (val) {
      if (!val || val.length < 8 || this.isEditing) return
      const response = await axios.get('/cep?cep=' + val)
      this.form.zip_code = response.data.cep
      this.form.district = response.data.bairro
      this.form.complement = response.data.complemento
      this.form.street = response.data.logradouro
      this.form.number = response.data.numero
      this.form.province_id = response.data.province_id
      this.form.city_id = response.data.city_id
    },
    editing (val) {
      if (!Object.keys(val).length) return null
      this.isEditing = true

      this.form = val

      setTimeout(() => {
        this.isEditing = false
      }, 200)
    },
    async 'form.province_id' () {
      await this.getCities()
    }
  }
}
</script>

<style lang="css">
.subtitle_form {
  position: relative;
  width: 100%;
}
.modal-chevron {
  color: #05b446;
  position: absolute;
  right: 10px;
  font-size: 22px;
  top: 50%;
  transition: 0.2s;
  transform: translateY(-50%) rotate(360deg);
}
.modal-chevron.open {
  transform: translateY(-50%) rotate(180deg);
}
.cursor-pointer {
  cursor: pointer;
}
</style>
